import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, useTheme } from '@mui/material';
import SetHeaderData from '../components/SetHeaderData';

const MakeUp = ({ language }) => {
  const theme = useTheme();
  const [content, setContent] = useState(null);

  useEffect(() => {
    const loadContent = async () => {
      const response = await import(`../data/make_up/${language}.json`);
      setContent(response);
    };
    loadContent();
  }, [language]);

  if (!content) return <Typography>Loading...</Typography>;

  return (
    <Container>

      <SetHeaderData content={content} />

      <Box sx={{ my: 4 }}>

        <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.section1.title}</Typography>
        
        <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.section1.paragraph1}</Typography>

        <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}>
          <Typography variant="h6" component="h2" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.section2.title}</Typography>
          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.section2.paragraph1}</Typography>

          <Box
            component="img"
            sx={{
              width: '100%',
              maxWidth: 1316,
              margin: '0 auto',
              display: 'block',
              borderRadius: '10px'
            }}
            alt="Day Make Up"
            src="/images/out/day_make_up.jpg"
          />

          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.section2.paragraph2}</Typography>
        </Box>

        <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}>
          <Typography variant="h6" component="h2" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.section3.title}</Typography>
          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.section3.paragraph1}</Typography>
          <Box
            component="img"
            sx={{
              width: '100%',
              maxWidth: 1254,
              margin: '0 auto',
              display: 'block',
              borderRadius: '10px'
            }}
            alt="Glam Make Up"
            src="/images/out/glam_make_up.jpg"
          />
        </Box>

        <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}>
          <Typography variant="h6" component="h2" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.section4.title}</Typography>
          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.section4.paragraph1}</Typography>

          <Box
            component="img"
            sx={{
              width: '100%',
              maxWidth: 1213,
              margin: 'auto',
              display: 'block',
              borderRadius: '10px',
              my: 2
            }}
            alt="Dynamic Make Up"
            src="/images/out/dynamic_make_up.jpg"
          />

          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.section4.paragraph2}</Typography>
        </Box>

      </Box>
    </Container>
  );
}

export default MakeUp;