import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Link as MuiLink, useTheme, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import SetHeaderData from '../components/SetHeaderData';

const SpecialPromotions = ({ language }) => {
  const theme = useTheme();
  const [content, setContent] = useState(null);

  useEffect(() => {
    const loadContent = async () => {
      const response = await import(`../data/special_promotions/${language}.json`);
      setContent(response);
    };
    loadContent();
  }, [language]);

  const calculatePrice = (units, pricePerUnit) => {
    return units * pricePerUnit;
  };

  if (!content) return <Typography>Loading...</Typography>;

  return (
    <Container>

      <SetHeaderData content={content} />

      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.title}</Typography>
        <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.description}</Typography>

        {content.sections.map((section, index) => (
          <Box key={index} 
            sx={{ 
              textAlign: 'center', 
              my: 4, 
              p: 2, 
              border: '1px solid #ddd',
              borderColor: theme.palette.divider,
              borderRadius: 2, 
              backgroundColor: theme.palette.background.paper
            }}>
            <Typography variant="h5" component="h2" gutterBottom>{section.title}</Typography>
            <Typography variant="body1" component="p">{section.content} </Typography>
            
            <Button component={Link} to={section.link} variant="contained"
              sx={{ textDecoration: 'none', color: theme.palette.divider }}>
                {section.link_text}
            </Button>
            
            <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', mt: 2 }}>{content.price_name}: {calculatePrice(5, section.price)}</Typography>
            <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center'}}>{content.duration_name}: {section.duration_per_session}</Typography>
          </Box>
        ))}

        <Box
          sx={{ 
            textAlign: 'center', 
            my: 4, 
            p: 2, 
            border: '1px solid #ddd',
            borderColor: theme.palette.divider,
            borderRadius: 2, 
            backgroundColor: theme.palette.background.paper 
          }}>

          <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center', my: 2 }}>
            {content.terms_conditions_title}
          </Typography>
          <Box sx={{ textAlign: 'center' }}>
            {content.terms_conditions.map((condition, index) => (
              <Typography key={index} variant="body1" component="p" gutterBottom>
                {condition}
              </Typography>
            ))}
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default SpecialPromotions;