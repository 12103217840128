import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, useTheme } from '@mui/material';
import SetHeaderData from '../components/SetHeaderData';

const HairdresserPrices = ({ language }) => {
  const theme = useTheme();
  const [content, setContent] = useState(null);
  const [prices, setPrices] = useState(null);
  const [rowsHaircut, setRowsHaircut] = useState(null);
  const [rowsWedding, setRowsWedding] = useState(null);
  const [rowsMakeUp, setRowsMakeUp] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        const contentResponse = await import(`../data/hairdresser_prices/${language}.json`);
        const pricesResponse = await import(`../data/hairdresser_prices/prices.json`);
        setContent(contentResponse);
        setPrices(pricesResponse);
      } catch (error) {
        console.error("Error loading data: ", error);
      }
    };
    loadData();
  }, [language]);

  useEffect(() => {
    if (content && prices) {
      const loadRows = () => {
        setRowsHaircut([
          { name: content.haircut_lady, short: prices.haircut_lady.short, medium: prices.haircut_lady.medium, long: prices.haircut_lady.long, very_long: prices.haircut_lady.very_long },
          { name: content.haircut_man, short: prices.haircut_man.short, medium: prices.haircut_man.medium, long: prices.haircut_man.long, very_long: prices.haircut_man.very_long },
          { name: content.hair_perm, short: prices.hair_perm.short, medium: prices.hair_perm.medium, long: prices.hair_perm.long, very_long: prices.hair_perm.very_long },
          { name: content.hair_coloring, short: prices.hair_coloring.short, medium: prices.hair_coloring.medium, long: prices.hair_coloring.long, very_long: prices.hair_coloring.very_long },
          { name: content.highlighting, short: prices.highlighting.short, medium: prices.highlighting.medium, long: prices.highlighting.long, very_long: prices.highlighting.very_long },
          { name: content.wash_dry, short: prices.wash_dry.short, medium: prices.wash_dry.medium, long: prices.wash_dry.long, very_long: prices.wash_dry.very_long },
          { name: content.hear_treatment, short: prices.hear_treatment.short, medium: prices.hear_treatment.medium, long: prices.hear_treatment.long, very_long: prices.hear_treatment.very_long },
          { name: content.hair_straightening, short: prices.hair_straightening.short, medium: prices.hair_straightening.medium, long: prices.hair_straightening.long, very_long: prices.hair_straightening.very_long }
        ]);

        setRowsWedding([
          { name: content.wedding_hairstyle, price: prices.wedding_hairstyle },
          { name: content.wedding_hairstyle_fancy, price: prices.wedding_hairstyle_fancy }
        ]);

        setRowsMakeUp([
          { name: content.basic, price: prices.make_up.basic },
          { name: content.day, price: prices.make_up.day },
          { name: content.evening, price: prices.make_up.evening },
          { name: content.wedding_makeup, price: prices.make_up.wedding_makeup },
          { name: content.dynamic, price: prices.make_up.dynamic },
          { name: content.wedding_combi_makeup_hairstyle, price: prices.make_up.wedding_combi_makeup_hairstyle }
        ]);
      };
      loadRows();
    }
  }, [content, prices]);

  if (!content || !rowsHaircut || !rowsWedding || !rowsMakeUp) return <Typography>Loading...</Typography>;

  return (
    <Container>

      <SetHeaderData content={content} />

      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center', my: 2 }}>
          {content.title}
        </Typography>
        
        <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}>
          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4, mb: 2 }}>{content.haircut_title}</Typography>
          <TableContainer component={Paper} sx={{ mb: 4 }}>
            <Table sx={{ minWidth: 500, maxWidth: 1200 }} aria-label="haircut prices table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>{content.service}</TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>{content.hair_length.short}</TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>{content.hair_length.medium}</TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>{content.hair_length.long}</TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>{content.hair_length.very_long}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsHaircut.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">{row.name}</TableCell>
                    <TableCell align="right">{row.short}</TableCell>
                    <TableCell align="right">{row.medium}</TableCell>
                    <TableCell align="right">{row.long}</TableCell>
                    <TableCell align="right">{row.very_long}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Typography variant="body2" sx={{ mt: 1, fontStyle: 'italic' }}>
            * {content.hair_length.very_long}) {content.asterisk}
          </Typography>

          <Typography variant="body2" sx={{ mt: 1, mb: 4, fontStyle: 'italic' }}>
            ** {content.hair_straightening_asterisk}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}>
          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4, mb: 2 }}>
            {content.wedding_title}
          </Typography>
          <TableContainer component={Paper} sx={{ mb: 4, width: '100%', maxWidth: 500 }}>
            <Table sx={{ minWidth: 350 }} aria-label="wedding prices table">
              <TableBody>
                {rowsWedding.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">{row.name}</TableCell>
                    <TableCell align="right">{row.price}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>


        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}>
          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4, mb: 2 }}>
            {content.make_up_title}
          </Typography>
          <TableContainer component={Paper} sx={{ mb: 4, width: '100%', maxWidth: 500 }}>
            <Table sx={{ minWidth: 350, maxWidth: 500 }} aria-label="make-up prices table">
              <TableBody>
                {rowsMakeUp.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">{row.name}</TableCell>
                    <TableCell align="right">{row.price}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        </Box>
    </Container>
  );
}

export default HairdresserPrices;
