import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';

const CookieConsentContainer = styled(Box)({
  position: 'fixed',
  bottom: 0,
  width: '100%',
  backgroundColor: '#333',
  color: '#fff',
  textAlign: 'center',
  padding: '1em',
  zIndex: 1000,
});

const CookieConsent = ({language}) => {
  const [visible, setVisible] = useState(true);
  const [content,setContent] = useState(true);

  useEffect(() => {
    const loadContent = async () => {
      const response = await import(`../data/cookie_consent/${language}.json`);
      setContent(response);
    };
    loadContent();
  }, [language]);

  if (!content) return <Typography>Loading...</Typography>;

  const acceptCookies = () => {
    // Handle cookie acceptance (e.g., set a cookie, localStorage, etc.)
    setVisible(false);
  };

  if (!visible) return null;

  return (
    <CookieConsentContainer>
      <Typography variant="body2">{content.consent}</Typography>
      <Button onClick={acceptCookies} sx={{ ml: 2 }} variant="contained" color="primary">
        Accept
      </Button>
    </CookieConsentContainer>
  );
};

export default CookieConsent;
