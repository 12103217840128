import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, useTheme, Alert } from '@mui/material';
import SetHeaderData from '../components/SetHeaderData';

const MassageTypes = ({ language }) => {
  const theme = useTheme();
  const [content, setContent] = useState(null);
  const [priceContent, setPriceContent] = useState(null);
  const [prices, setPrices] = useState(null);
  const [rowsFootReflexology, setRowsFootReflexology] = useState(null);
  const [rowsHotStone, setRowsHotStone] = useState(null);
  const [rowsTraditionalThai, setRowsTraditionalThai] = useState(null);
  const [rowsAromaOil, setRowsAromaOil] = useState(null);
  const [rowsCouples, setRowsCouples] = useState(null);
  const [rowsSignature, setRowsSignature] = useState(null);
  const [rowsIndianHead, setRowsIndianHead] = useState(null);
  const [rowsThaiHerbal, setRowsThaiHerbal] = useState(null);
  const [rowsHimalayanStoneEnhancement, setRowsHimalayanStoneEnhancement] = useState(null);

  useEffect(() => {
    const loadContent = async () => {
      const response = await import(`../data/massage_types/${language}.json`);
      const priceContentResponse = await import(`../data/massage_prices/${language}.json`);
      const pricesResponse = await import(`../data/massage_prices/prices.json`);
      setPriceContent(priceContentResponse);
      setContent(response);
      setPrices(pricesResponse);
    };
    loadContent();
  }, [language]);

  useEffect(() => {
    if (prices) {
      const loadRows = () => {

        setRowsFootReflexology([
          { duration: prices.massage.foot_reflexology.short.duration, price: prices.massage.foot_reflexology.short.price, discount: prices.massage.foot_reflexology.short.price * 0.8},
          { duration: prices.massage.foot_reflexology.medium.duration, price: prices.massage.foot_reflexology.medium.price, discount: prices.massage.foot_reflexology.medium.price * 0.8},
          { duration: prices.massage.foot_reflexology.long.duration, price: prices.massage.foot_reflexology.long.price, discount: prices.massage.foot_reflexology.long.price * 0.8}
        ]);

        setRowsHotStone([
          { duration: prices.massage.hot_stone.short.duration, price: prices.massage.hot_stone.short.price, discount: prices.massage.hot_stone.short.price * 0.8},
          { duration: prices.massage.hot_stone.medium.duration, price: prices.massage.hot_stone.medium.price, discount: prices.massage.hot_stone.medium.price * 0.8},
          { duration: prices.massage.hot_stone.long.duration, price: prices.massage.hot_stone.long.price, discount: prices.massage.hot_stone.long.price * 0.8}
        ]);

        setRowsTraditionalThai([
          { duration: prices.massage.traditional_thai.short.duration, price: prices.massage.traditional_thai.short.price, discount: prices.massage.traditional_thai.short.price * 0.8},
          { duration: prices.massage.traditional_thai.medium.duration, price: prices.massage.traditional_thai.medium.price, discount: prices.massage.traditional_thai.medium.price * 0.8},
          { duration: prices.massage.traditional_thai.long.duration, price: prices.massage.traditional_thai.long.price, discount: prices.massage.traditional_thai.long.price * 0.8}
        ]);

        setRowsAromaOil([
          { duration: prices.massage.aroma_oil.short.duration, price: prices.massage.aroma_oil.short.price, discount: prices.massage.aroma_oil.short.price * 0.8},
          { duration: prices.massage.aroma_oil.medium.duration, price: prices.massage.aroma_oil.medium.price, discount: prices.massage.aroma_oil.medium.price * 0.8},
          { duration: prices.massage.aroma_oil.long.duration, price: prices.massage.aroma_oil.long.price, discount: prices.massage.aroma_oil.long.price * 0.8}
        ]);

        setRowsCouples([
          { duration: prices.massage.couples.short.duration, price: prices.massage.couples.short.price, discount: prices.massage.couples.short.price * 0.8},
          { duration: prices.massage.couples.medium.duration, price: prices.massage.couples.medium.price, discount: prices.massage.couples.medium.price * 0.8},
          { duration: prices.massage.couples.long.duration, price: prices.massage.couples.long.price, discount: prices.massage.couples.long.price * 0.8}
        ]);

        setRowsSignature([
          { duration: prices.massage.signature.short.duration, price: prices.massage.signature.short.price, discount: prices.massage.signature.short.price * 0.8},
          { duration: prices.massage.signature.medium.duration, price: prices.massage.signature.medium.price, discount: prices.massage.signature.medium.price * 0.8}
        ]);

        setRowsIndianHead([
          { duration: prices.massage.indian_head.short.duration, price: prices.massage.indian_head.short.price, discount: prices.massage.indian_head.short.price * 0.8},
          { duration: prices.massage.signature.medium.duration, price: prices.massage.indian_head.medium.price, discount: prices.massage.indian_head.medium.price * 0.8}
        ]);

        setRowsThaiHerbal([
          { duration: prices.massage.thai_herbal.short.duration, price: prices.massage.thai_herbal.short.price, discount: prices.massage.thai_herbal.short.price * 0.8 },
          { duration: prices.massage.thai_herbal.medium.duration, price: prices.massage.thai_herbal.medium.price, discount: prices.massage.thai_herbal.medium.price * 0.8 },
          { duration: prices.massage.thai_herbal.long.duration, price: prices.massage.thai_herbal.long.price, discount: prices.massage.thai_herbal.long.price * 0.8 }
        ]);

        setRowsHimalayanStoneEnhancement([
          { duration: prices.massage.himalayan_stone_enhancement.short.duration, price: prices.massage.himalayan_stone_enhancement.short.price, discount: prices.massage.himalayan_stone_enhancement.short.price * 0.8 }
        ]);
      };
        loadRows();
    }
  }, [prices]);

  if (!content || 
    !rowsFootReflexology || 
    !rowsHotStone || 
    !rowsTraditionalThai ||
    !rowsAromaOil ||
    !rowsCouples ||
    !rowsSignature ||
    !rowsIndianHead ||
    !rowsThaiHerbal ||
    !rowsHimalayanStoneEnhancement
  ) return <Typography>Loading...</Typography>;

  return (
    <Container>

      <SetHeaderData content={content} />

      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.title}</Typography>

        <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.description}</Typography>

        <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Typography variant="h5" component="h2" gutterBottom sx={{ textAlign: 'center'}}>{content.couples.title}</Typography>
          <Box
            component="img"
            sx={{
              width: '100%',
              maxWidth: 1330,
              margin: '0 auto',
              display: 'block',
              borderRadius: '10px'
            }}
            alt="Facial Massage"
            src="/images/out/couple_massage.jpg"
          />
          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.couples.description}</Typography>
          
          <Alert
            severity="info" // You can use 'warning', 'error', 'success' as well
            sx={{ textAlign: 'center', my: 2, backgroundColor: theme.palette.divider, color: theme.palette.primary.main, fontWeight: 'bold' }}>
            {content.couples.disclaimer}
          </Alert>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <TableContainer component={Paper} sx={{ mb: 4, maxWidth: 500 }}>
              <Table sx={{ minWidth: 150 }} aria-label="couples prices table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>{priceContent.duration}</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>{priceContent.price} ({content.couples.price_summary})</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>{priceContent.discount}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsCouples.map((row) => (
                    <TableRow key={row.duration}>
                      <TableCell component="th" scope="row">{row.duration} {priceContent.duration_metric}:</TableCell>
                      <TableCell align="right">{row.price}.-</TableCell>
                      <TableCell align="right">{row.discount}.-</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Typography variant="body2" sx={{ mt: 1, fontStyle: 'italic' }}>
            {content.asterisk}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Typography variant="h5" component="h2" gutterBottom sx={{ textAlign: 'center'}}>{content.foot_reflexology.title}</Typography>
          <Box
            component="img"
            sx={{
              width: '100%',
              maxWidth: 1257,
              margin: '0 auto',
              display: 'block',
              borderRadius: '10px'
            }}
            alt="Facial Massage"
            src="/images/out/foot_reflexology.jpg"
          />
          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.foot_reflexology.description}</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <TableContainer component={Paper} sx={{ mb: 4, maxWidth: 400 }}>
              <Table sx={{ minWidth: 150 }} aria-label="foot reflexology prices table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>{priceContent.duration}</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>{priceContent.price}</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>{priceContent.discount}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsFootReflexology.map((row) => (
                    <TableRow key={row.duration}>
                      <TableCell component="th" scope="row">{row.duration} {priceContent.duration_metric}:</TableCell>
                      <TableCell align="right">{row.price}.-</TableCell>
                      <TableCell align="right">{row.discount}.-</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>

        <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Typography variant="h5" component="h2" gutterBottom sx={{ textAlign: 'center'}}>{content.hot_stone.title}</Typography>
          <Box
            component="img"
            sx={{
              width: '100%',
              maxWidth: 1254,
              margin: '0 auto',
              display: 'block',
              borderRadius: '10px'
            }}
            alt="Facial Massage"
            src="/images/out/hot_stone_massage.jpg"
          />
          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.hot_stone.description}</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <TableContainer component={Paper} sx={{ mb: 4, maxWidth: 400 }}>
              <Table sx={{ minWidth: 150 }} aria-label="hot stone prices table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>{priceContent.duration}</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>{priceContent.price}</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>{priceContent.discount}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsHotStone.map((row) => (
                    <TableRow key={row.duration}>
                      <TableCell component="th" scope="row">{row.duration} {priceContent.duration_metric}:</TableCell>
                      <TableCell align="right">{row.price}.-</TableCell>
                      <TableCell align="right">{row.discount}.-</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          </Box>

          <Box
            sx={{
              textAlign: 'center',
              my: 4,
              p: 2,
              border: '1px solid',
              borderColor: theme.palette.divider,
              borderRadius: 2,
              backgroundColor: theme.palette.background.paper,
            }}
          >
          <Typography variant="h5" component="h2" gutterBottom sx={{ textAlign: 'center'}}>{content.traditional_thai.title}</Typography>
          <Box
            component="img"
            sx={{
              width: '100%',
              maxWidth: 1365,
              margin: '0 auto',
              display: 'block',
              borderRadius: '10px'
            }}
            alt="Facial Massage"
            src="/images/out/traditional_thai.jpg"
          />
          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.traditional_thai.description}</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <TableContainer component={Paper} sx={{ mb: 4, maxWidth: 400 }}>
              <Table sx={{ minWidth: 150 }} aria-label="traditional thai prices table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>{priceContent.duration}</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>{priceContent.price}</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>{priceContent.discount}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsTraditionalThai.map((row) => (
                    <TableRow key={row.duration}>
                      <TableCell component="th" scope="row">{row.duration} {priceContent.duration_metric}:</TableCell>
                      <TableCell align="right">{row.price}.-</TableCell>
                      <TableCell align="right">{row.discount}.-</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          </Box>

          <Box
            sx={{
              textAlign: 'center',
              my: 4,
              p: 2,
              border: '1px solid',
              borderColor: theme.palette.divider,
              borderRadius: 2,
              backgroundColor: theme.palette.background.paper,
            }}
          >
          <Typography variant="h5" component="h2" gutterBottom sx={{ textAlign: 'center'}}>{content.aroma_oil.title}</Typography>
          <Box
            component="img"
            sx={{
              width: '100%',
              maxWidth: 1254,
              margin: '0 auto',
              display: 'block',
              borderRadius: '10px'
            }}
            alt="Facial Massage"
            src="/images/out/aroma_therapy.jpg"
          />
          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.aroma_oil.description}</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <TableContainer component={Paper} sx={{ mb: 4, maxWidth: 400 }}>
              <Table sx={{ minWidth: 150 }} aria-label="aroma oil prices table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>{priceContent.duration}</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>{priceContent.price}</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>{priceContent.discount}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsAromaOil.map((row) => (
                    <TableRow key={row.duration}>
                      <TableCell component="th" scope="row">{row.duration} {priceContent.duration_metric}:</TableCell>
                      <TableCell align="right">{row.price}.-</TableCell>
                      <TableCell align="right">{row.discount}.-</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          </Box>

          <Box
            sx={{
              textAlign: 'center',
              my: 4,
              p: 2,
              border: '1px solid',
              borderColor: theme.palette.divider,
              borderRadius: 2,
              backgroundColor: theme.palette.background.paper,
            }}
          >
          <Typography variant="h5" component="h2" gutterBottom sx={{ textAlign: 'center'}}>{content.signature.title}</Typography>
          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.signature.description}</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <TableContainer component={Paper} sx={{ mb: 4, maxWidth: 400 }}>
              <Table sx={{ minWidth: 150 }} aria-label="signature prices table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>{priceContent.duration}</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>{priceContent.price}</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>{priceContent.discount}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsSignature.map((row) => (
                    <TableRow key={row.duration}>
                      <TableCell component="th" scope="row">{row.duration} {priceContent.duration_metric}:</TableCell>
                      <TableCell align="right">{row.price}.-</TableCell>
                      <TableCell align="right">{row.discount}.-</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          </Box>

          <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Typography variant="h5" component="h2" gutterBottom sx={{ textAlign: 'center'}}>{content.indian_head.title}</Typography>
          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.indian_head.description}</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <TableContainer component={Paper} sx={{ mb: 4, maxWidth: 400 }}>
              <Table sx={{ minWidth: 150 }} aria-label="indian head prices table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>{priceContent.duration}</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>{priceContent.price}</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>{priceContent.discount}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsIndianHead.map((row) => (
                    <TableRow key={row.duration}>
                      <TableCell component="th" scope="row">{row.duration} {priceContent.duration_metric}:</TableCell>
                      <TableCell align="right">{row.price}.-</TableCell>
                      <TableCell align="right">{row.discount}.-</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          </Box>

        <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Typography variant="h5" component="h2" gutterBottom sx={{ textAlign: 'center'}}>{content.thai_herbal.title}</Typography>
          <Box
            component="img"
            sx={{
              width: '100%',
              maxWidth: 1254,
              margin: '0 auto',
              display: 'block',
              borderRadius: '10px'
            }}
            alt="Facial Massage"
            src="/images/out/herbal_stamp.jpg"
          />
          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.thai_herbal.description}</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <TableContainer component={Paper} sx={{ mb: 4, maxWidth: 400 }}>
              <Table sx={{ minWidth: 150 }} aria-label="thai herbal prices table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>{priceContent.duration}</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>{priceContent.price}</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>{priceContent.discount}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsThaiHerbal.map((row) => (
                    <TableRow key={row.duration}>
                      <TableCell component="th" scope="row">{row.duration} {priceContent.duration_metric}:</TableCell>
                      <TableCell align="right">{row.price}.-</TableCell>
                      <TableCell align="right">{row.discount}.-</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>

        <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Typography variant="h5" component="h2" gutterBottom sx={{ textAlign: 'center'}}>{content.himalayan_stone_enhancement.title}</Typography>
          <Box
            component="img"
            sx={{
              width: '100%',
              maxWidth: 1254,
              margin: '0 auto',
              display: 'block',
              borderRadius: '10px'
            }}
            alt="Facial Massage"
            src="/images/out/himalayan_stone.jpg"
          />
          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.himalayan_stone_enhancement.description}</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <TableContainer component={Paper} sx={{ mb: 4, maxWidth: 400 }}>
              <Table sx={{ minWidth: 150 }} aria-label="himalayan stone prices table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>{priceContent.duration}</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>{priceContent.price}</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>{priceContent.discount}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsHimalayanStoneEnhancement.map((row) => (
                    <TableRow key={row.duration}>
                      <TableCell component="th" scope="row">{row.duration} {priceContent.duration_metric}:</TableCell>
                      <TableCell align="right">{row.price}.-</TableCell>
                      <TableCell align="right">{row.discount}.-</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>

      </Box>
    </Container>
  );
};

export default MassageTypes;