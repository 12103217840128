import { createTheme } from '@mui/material/styles';
import { amber } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#263a33',
    },
    secondary: {
      main: amber[500],
    },
    background: {
      banner: '#102820',
      default: '#40534d',
      paper: '#263a33',
    },
    text: {
      primary: '#ffffff',
      secondary: '#aaaaaa',
    },
    divider: '#E6B560',
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    
    // Refined custom blockquote variant
    blockquote: {
      textAlign: 'center',
      fontStyle: 'italic',
      padding: '24px',
      margin: '32px auto',
      maxWidth: '600px',
      color: '#dddddd',
      backgroundColor: 'rgba(255, 255, 255, 0.1)', // Slightly more pronounced background
      borderLeft: '4px solid #E6B560', // Accent border on the left
      borderRadius: '8px'
    }     
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: () => ({
          backgroundColor: '#2d3a38',
          color: '#ffffff',
          border: `1px solid ${amber[500]}`,
          borderRadius: '8px',
        }),
      },
    },
  },
});

export default theme;
