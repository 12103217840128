import React, { useState, useEffect } from 'react';
import { Container, TableContainer, Paper, Table, TableBody, TableRow, TableCell, Button, Typography, Box, useTheme } from '@mui/material';
import SetHeaderData from '../components/SetHeaderData';
import { Link } from 'react-router-dom';


const HolidayAccommodation = ({ language }) => {
  const theme = useTheme();
  const [content, setContent] = useState(null);
  const [prices, setPrices] = useState(null);


  useEffect(() => {
    const loadContent = async () => {
      const response_language = await import(`../data/holiday_accommodation/${language}.json`);
      const response_prices = await import(`../data/holiday_accommodation/prices.json`);
      setContent(response_language);
      setPrices(response_prices);
      console.log("Prices: ", prices)
    };
    loadContent();
  }, [language]);

  if (!content || !prices) return <Typography>Loading...</Typography>;

  return (
    <Container>

      <SetHeaderData content={content} />

      <Box sx={{ my: 4 }}>

        <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.title}</Typography>

        <Box
            sx={{
              textAlign: 'center',
              my: 4,
              p: 2,
              border: '1px solid',
              borderColor: theme.palette.divider,
              borderRadius: 2,
              backgroundColor: theme.palette.background.paper,
            }}>
          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.paragraph1}</Typography>
          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.paragraph2}</Typography>
        </Box>

        <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}>
          <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center', my: 2 }}>
            {content.discounts.title}
          </Typography>
          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>
            {content.discounts.content}
          </Typography>

          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, mt: 2 }}>
            <Button component={Link} to={content.discounts.massage_types.link} variant="contained"
              sx={{ textDecoration: 'none', color: theme.palette.divider }}>
                {content.discounts.massage_types.description}
            </Button>
            <Button component={Link} to={content.discounts.body_treatments.link} variant="contained"
              sx={{ textDecoration: 'none', color: theme.palette.divider }}>
                {content.discounts.body_treatments.description}
            </Button>
            <Button component={Link} to={content.discounts.face_treatment.link} variant="contained" 
              sx={{ textDecoration: 'none', color: theme.palette.divider }}>
                {content.discounts.face_treatment.description}
            </Button>
            <Button component={Link} to={content.discounts.hair_spa.link} variant="contained"
              sx={{ textDecoration: 'none', color: theme.palette.divider }}>
                {content.discounts.hair_spa.description}
            </Button>
          </Box>

        </Box>

        <Box
            sx={{
              textAlign: 'center',
              my: 4,
              p: 2,
              border: '1px solid',
              borderColor: theme.palette.divider,
              borderRadius: 2,
              backgroundColor: theme.palette.background.paper,
            }}>
          <Typography variant="h5" component="h2" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.book_now.title}</Typography>
          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.book_now.paragraph}</Typography>


          <Box sx={{ textAlign: 'center', mt: 4 }}>
            <Button component={Link} variant="contained" sx={{ textDecoration: 'none', color: theme.palette.divider }} 
              to={content.book_now.goto.link} target="_blank">
                {content.book_now.goto.description}
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}>

          <Typography variant="h4" component="h2" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.appartement.title}</Typography>
          <Typography variant="body" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.appartement.content1}</Typography>

          <Box
            component="img"
            sx={{
              width: '100%',
              maxWidth: 1254,
              margin: '0 auto',
              display: 'block',
              borderRadius: '10px'
            }}
            alt="Activities"
            src="/images/out/appartement_lroom1_small.jpg"
          />
          <Typography variant="body" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.appartement.content2}</Typography>

          <Box
            component="img"
            sx={{
              width: '100%',
              maxWidth: 1254,
              margin: '0 auto',
              display: 'block',
              borderRadius: '10px',
              marginBottom: '20px'
            }}
            alt="Activities"
            src="/images/out/appartement_lroom2_small.jpg"
          />

          <Box
            component="img"
            sx={{
              width: '100%',
              maxWidth: 1254,
              margin: '0 auto',
              display: 'block',
              borderRadius: '10px'
            }}
            alt="Activities"
            src="/images/out/appartement_sroom1_small.jpg"
          />
          
          <Typography variant="body" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.appartement.content3}</Typography>
          
          <Box
            component="img"
            sx={{
              width: '100%',
              maxWidth: 1254,
              margin: '0 auto',
              display: 'block',
              borderRadius: '10px'
            }}
            alt="Activities"
            src="/images/out/appartement_sroom2_small.jpg"
          />

          <Typography variant="body" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.appartement.content4}</Typography>
          <Box
            component="img"
            sx={{
              width: '100%',
              maxWidth: 1254,
              margin: '0 auto',
              display: 'block',
              borderRadius: '10px'
            }}
            alt="Activities"
            src="/images/out/appartement_kitchen3_small.jpg"
          />

          <Typography variant="body" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.appartement.content5}</Typography>

          <Box
            component="img"
            sx={{
              width: '100%',
              maxWidth: 1254,
              margin: '0 auto',
              display: 'block',
              borderRadius: '10px',
              marginBottom: '20px'
            }}
            alt="Activities"
            src="/images/out/appartement_kitchen1_small.jpg"
          />

          <Typography variant="body" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.appartement.content6}</Typography>
          
          <Box
            component="img"
            sx={{
              width: '100%',
              maxWidth: 600,
              margin: '0 auto',
              display: 'block',
              borderRadius: '10px',
              marginBottom: '20px'
            }}
            alt="Activities"
            src="/images/out/appartement_kitchen2_small.jpg"
          />

          <Box
            component="img"
            sx={{
              width: '100%',
              maxWidth: 600,
              margin: '0 auto',
              display: 'block',
              borderRadius: '10px',
              marginBottom: '20px'
            }}
            alt="Activities"
            src="/images/out/appartement_terrace_small.jpg"
          />

          <Box
            component="img"
            sx={{
              width: '100%',
              maxWidth: 600,
              margin: '0 auto',
              display: 'block',
              borderRadius: '10px'
            }}
            alt="Activities"
            src="/images/out/appartement_washtower.jpg"
          />

          <Typography variant="body" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.appartement.content7}</Typography>

          <Box
            component="img"
            sx={{
              width: '100%',
              maxWidth: 600,
              margin: '0 auto',
              display: 'block',
              borderRadius: '10px'
            }}
            alt="Activities"
            src="/images/out/appartement_shower1_small.jpg"
          />

          <Typography variant="body" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.appartement.content8}</Typography>

          <Box
            component="img"
            sx={{
              width: '100%',
              maxWidth: 1254,
              margin: '0 auto',
              display: 'block',
              borderRadius: '10px',
              marginBottom: '20px'
            }}
            alt="Activities"
            src="/images/out/appartement_shower2_small.jpg"
          />

          <Typography variant="body" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.appartement.content9}</Typography>
          
          <Box
            component="img"
            sx={{
              width: '100%',
              maxWidth: 600,
              margin: '0 auto',
              display: 'block',
              borderRadius: '10px',
              marginBottom: '20px'
            }}
            alt="Activities"
            src="/images/out/appartement_toilet_small.jpg"
          />


        <Box
            component="img"
            sx={{
              width: '100%',
              maxWidth: 600,
              margin: '0 auto',
              display: 'block',
              borderRadius: '10px'
            }}
            alt="Activities"
            src="/images/out/appartement_corridor_small.jpg"
          />

          <Typography variant="body" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.appartement.content10}</Typography>

          <Box
            component="img"
            sx={{
              width: '100%',
              maxWidth: 1254,
              margin: '0 auto',
              display: 'block',
              borderRadius: '10px',
              marginBottom: '20px'
            }}
            alt="Activities"
            src="/images/out/appartement_view_small.jpg"
          />

          <Typography variant="body" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.appartement.content11}</Typography>

          <Typography variant="body" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.appartement.content12}</Typography>
        
        </Box>

        <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}>
          <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center', my: 2 }}>
            {content.activities.title}
          </Typography>
          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>
            {content.activities.content1}
          </Typography>

          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>
            {content.activities.content2}
          </Typography>

          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>
            {content.activities.content3}
          </Typography>

          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, mt: 2 }}>
            <Button variant="contained" sx={{ textDecoration: 'none', color: theme.palette.divider }} href={content.activities.bar1.link}>{content.activities.bar1.description}</Button>
            <Button variant="contained" sx={{ textDecoration: 'none', color: theme.palette.divider }} href={content.activities.bar2.link}>{content.activities.bar2.description}</Button>
            <Button variant="contained" sx={{ textDecoration: 'none', color: theme.palette.divider }} href={content.activities.restaurant1.link}>{content.activities.restaurant1.description}</Button>
            <Button variant="contained" sx={{ textDecoration: 'none', color: theme.palette.divider }} href={content.activities.restaurant2.link}>{content.activities.restaurant2.description}</Button>
            <Button variant="contained" sx={{ textDecoration: 'none', color: theme.palette.divider }} href={content.activities.restaurant3.link}>{content.activities.restaurant3.description}</Button>
          </Box>
        </Box>
      </Box>
  </Container>
  )
};

export default HolidayAccommodation;