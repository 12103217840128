import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Card, CardContent, useTheme} from '@mui/material';
import SetHeaderData from '../components/SetHeaderData';

const Home = ({ language }) => {
  const [content, setContent] = useState(null);
  const theme = useTheme();;

  useEffect(() => {
    const loadContent = async () => {
      const response = await import(`../data/home/${language}.json`);
      setContent(response);
    };
    loadContent();
  }, [language]);

  if (!content) return <Typography>Loading...</Typography>;

  return (
    <Container>

      <SetHeaderData content={content} />

      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center', my: 2 }}>
          {content.title}
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom sx={{ textAlign: 'center', my: 2 }}>
          {content.subtitle}
        </Typography>

        <Card sx={{ my: 4 }}>
          <CardContent>
            <Typography variant="h6" sx={{ textAlign: 'center', py: 2 }}>{content.announcement_title}</Typography>
            <Typography variant="body" component="p" sx={{ textAlign: 'center', py: 2 }}>{content.announcement_content}</Typography>
            <Box
              component="img"
              sx={{
                width: '100%',
                maxWidth: 800,
                margin: '0 auto',
                display: 'block',
              }}
              alt="Voucher"
              src="/images/out/voucher.png"
            /> 
          </CardContent>
        </Card>

        <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}>
        
          <Typography variant="h5" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.sections.section1.title}</Typography>
          <Typography variant="body" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.sections.section1.content1}</Typography>
          <Typography variant="body" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.sections.section1.content2}</Typography>
          <Typography variant="body" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.sections.section1.content3}</Typography>
          <Typography variant="body" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.sections.section1.content4}</Typography>

          <Box
            component="img"
            sx={{
              width: '100%',
              maxWidth: 1254,
              margin: '0 auto',
              display: 'block',
              borderRadius: '10px',
            }}
            alt="Entree 2"
            src="/images/out/rachinee_entree2_small.jpg"
          />

        </Box>

        <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}>

          <Typography variant="h5" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.sections.section2.title}</Typography>
          <Typography variant="body" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.sections.section2.content}</Typography>
        
          <Box
            component="img"
            sx={{
              width: '100%',
              maxWidth: 1254,
              margin: '0 auto',
              display: 'block',
              borderRadius: '10px',
            }}
            alt="Entree 1"
            src="/images/out/rachinee_entree1_small.jpg"
          />

          <Typography variant="h5" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.sections.section3.title}</Typography>
          <Typography variant="body" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.sections.section3.content}</Typography>
          <Box
            component="img"
            sx={{
              width: '100%',
              maxWidth: 1254,
              margin: '0 auto',
              display: 'block',
              borderRadius: '10px',
              marginBottom: '20px'
            }}
            alt="Couple Massage"
            src="/images/out/rachinee_couple_massage_small.jpg"
          />

          <Typography variant="h5" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.sections.section4.title}</Typography>
          <Typography variant="body" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.sections.section4.content}</Typography>
          <Box
            component="img"
            sx={{
              width: '100%',
              maxWidth: 1254,
              margin: '0 auto',
              display: 'block',
              borderRadius: '10px',
              marginBottom: '20px'
            }}
            alt="Thai Massage"
            src="/images/out/rachinee_thai_massage_small.jpg"
          />

          <Typography variant="h5" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.sections.section5.title}</Typography>
          <Typography variant="body" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.sections.section5.content}</Typography>
          <Box
            component="img"
            sx={{
              width: '100%',
              maxWidth: 1254,
              margin: '0 auto',
              display: 'block',
              borderRadius: '10px',
              marginBottom: '20px'
            }}
            alt="Foot Massage"
            src="/images/out/rachinee_foot_massage_small.jpg"
          />

          <Typography variant="h5" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.sections.section6.title}</Typography>
          <Typography variant="body" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.sections.section6.content}</Typography>
          <Box
            component="img"
            sx={{
              width: '100%',
              maxWidth: 1254,
              margin: '0 auto',
              display: 'block',
              borderRadius: '10px',
              marginBottom: '20px'
            }}
            alt="Scrub Corner"
            src="/images/out/rachinee_scrub_corner_small.jpg"
          />

        </Box>

        <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}>
          <Typography variant="h5" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.sections.section7.title}</Typography>
          <Typography variant="body" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.sections.section7.content}</Typography>

        </Box>

        
        <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}>

          <Typography variant="h5" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.sections.section8.title}</Typography>
          <Typography variant="body" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.sections.section8.content}</Typography>
          <Box
            component="img"
            sx={{
              width: '100%',
              maxWidth: 1254,
              margin: '0 auto',
              display: 'block',
              borderRadius: '10px',
            }}
            alt="Hairdresser"
            src="/images/out/rachinee_hairdresser_small.jpg"
          />

          <Typography variant="h5" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.sections.section9.title}</Typography>
          <Typography variant="body" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.sections.section9.content}</Typography>

        </Box>

        <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}>
          <Typography variant="h5" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.sections.section10.title}</Typography>
          <Typography variant="body" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.sections.section10.content}</Typography>
        </Box>

                
        <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}>

          <Box
            component="img"
            sx={{
              width: '100%',
              maxWidth: 1254,
              margin: '0 auto',
              display: 'block',
              borderRadius: '10px',
            }}
            alt="Hairdresser"
            src="/images/out/rachinee_cosmetic_small.jpg"
          />
          <Typography variant="h5" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.sections.section11.title}</Typography>
          <Typography variant="body" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.sections.section11.content}</Typography>
        </Box>

        <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}>

          <Box
            component="img"
            sx={{
              width: '100%',
              maxWidth: 1254,
              margin: '0 auto',
              display: 'block',
              borderRadius: '10px',
            }}
            alt="Corridor"
            src="/images/out/rachinee_corridor_small.jpg"
          />
        </Box>
      </Box>
    </Container>
  );
};

export default Home;
