import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, useTheme, Button } from '@mui/material';
import SetHeaderData from '../components/SetHeaderData';

const ad = ({setLanguage}) => {
  const theme = useTheme();
  const ad = new URLSearchParams(location.search).get('ad') || 'a';
  const language = new URLSearchParams(location.search).get('lang') || 'de';
  setLanguage(language)
  const [content, setContent] = useState(null);

  //if (!language){ language = language_param}

  useEffect(() => {
    const loadContent = async () => {
      const response = await import(`../data/ads/${language}.json`);
      setContent(response);
    };
    loadContent();
  }, [language]);

  if (!content || !language) return <Typography>Loading...</Typography>;

  return (
    <Container>
      <SetHeaderData content={content} />
      <Box sx={{ my: 4 }}>

        <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}
          >
          <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content[ad].title}</Typography>

          <Box
            component="img"
            sx={{
              width: '100%',
              maxWidth: 1254,
              margin: '0 auto',
              display: 'block',
              borderRadius: '10px',
            }}
            alt={content[ad].image1}
            src={content[ad].image1}
          />
          
          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content[ad].paragraph1}</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, my:2 }}>
            <Button variant="contained" sx={{ textDecoration: 'none', color: theme.palette.divider }} href={content[ad].link1}>{content[ad].link1_description}</Button>
            <Button variant="contained" sx={{ textDecoration: 'none', color: theme.palette.divider }} href={content[ad].link2}>{content[ad].link2_description}</Button>
          </Box>

          <Box
            component="img"
            sx={{
              width: '100%',
              maxWidth: 1254,
              margin: '0 auto',
              display: 'block',
              borderRadius: '10px',
            }}
            alt={content[ad].image2}
            src={content[ad].image2}
          />
          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content[ad].paragraph2}</Typography>

          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, my: 2 }}>
            <Button variant="contained" sx={{ textDecoration: 'none', color: theme.palette.divider }} href={content[ad].link3}>{content[ad].link3_description}</Button>
          </Box>

          <Box
            component="img"
            sx={{
              width: '100%',
              maxWidth: 1254,
              margin: '0 auto',
              display: 'block',
              borderRadius: '10px',
            }}
            alt={content[ad].image3}
            src={content[ad].image3}
          />
          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content[ad].paragraph3}</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, my: 2 }}>
            <Button variant="contained" sx={{ textDecoration: 'none', color: theme.palette.divider }} href={content[ad].link4}>{content[ad].link4_description}</Button>
            <Button variant="contained" sx={{ textDecoration: 'none', color: theme.palette.divider }} href={content[ad].link1}>{content[ad].link1_description}</Button>
          </Box>
        </Box>

        <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Typography 
            variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center', my: 2 }}
          >
            {content.testimonials_title}
          </Typography>
          
          {content.testimonials.map((testimonial, index) => (
            <Typography 
              key={index} 
              variant="blockquote" 
              component="body"
              gutterBottom
            >
              {testimonial}
            </Typography>          
          ))}
        </Box>
      </Box>
    </Container>
  );
};

export default ad;